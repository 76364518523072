import React from 'react';
import {Container, Row} from "reactstrap";
import Colxx from "../../Colxx";
import Image from "next/legacy/image";

import iphoneImage from "../../../icons/landing/img-iphone-mockup.png";

import appstoreBtn from "../../../icons/buttons/btn-appstore@2x.png";
import playstoreBtn from "../../../icons/buttons/btn-playstore@2x.png";

const Mobille = () => {
    return (
        <section className="products-block products-block-dark mobile-section">
            <Container>
                <Row className="info-section">
                    <Colxx xxl="6" className="d-none d-xxl-block phone">
                        <div className="img__phone">
                            <Image
                                src={iphoneImage}
                                alt="logo"
                                loading={"eager"}
                                layout={'fill'}
                            />
                        </div>
                    </Colxx>
                    <Colxx xxl="6" className="product-info">
                        <h2 className="azure">Available for your Smartphone</h2>
                        <p>Etiam egestas mi id blandit ultricies. Duis porta tincidunt ligula, ac congue risus loborti sit amet. Suspendisse vehicula sollicitudin enim.</p>
                        <div className="stores">
                            <a href="https://www.apple.com/ru/ios/app-store/">
                                <Image src={appstoreBtn} alt="appstore" width={195} height={57}/>
                            </a>
                            <a href="https://play.google.com">
                                <Image src={playstoreBtn} alt="playstore" width={195} height={57}/>
                            </a>
                        </div>
                    </Colxx>
                </Row>
            </Container>
        </section>
    );
};

export default Mobille;