import React from 'react';
import {Container, Row} from "reactstrap";
import ItemSection from "./ItemSection";

import strengthLogo from '../../../icons/landing/ic-strength.svg'
import planLogo from "../../../icons/landing/ic-plan.svg";
import phoneLogo from "../../../icons/landing/ic-phone.svg";

const data = [
    {
        id:1,
        src: strengthLogo,
        label: 'Curabitur dolor dui',
        text: 'Maecenas pulvinar, nunc in gravida lobortis, dui lacus maxi\n' +
            'mus ex, eget convallis felis erat at purus. Cras id ante efficit\n' +
            'ur, fringilla metus sed, blandit nunc.'
    },
    {
        id:2,
        src: planLogo,
        label: 'Morbi et turpis risus',
        text: 'Duis ultrices nec nisl eget malesuada. Mauris non condiment\n' +
        'um turpis. Nulla vel blandit est. Nulla tristique augue et\n' +
        'massa scelerisque, ut vulputate orci tincidunt.'
    },
    {
        id:3,
        src: phoneLogo,
        label: 'Morbi facilisis accumsan nunc',
        text: 'Maecenas pulvinar, nunc in gravida lobortis, dui lacus maxi\n'+
        'mus ex, eget convallis felis erat at purus. Cras id ante efficit\n'+
        'ur, fringilla metus sed, blandit nunc.'
    },
]

const SecondarySection = () => {
    return (
        <Container className="m-b-xl">
            <Row>
                {data.map(item => <ItemSection
                    key={item.id}
                    src={item.src}
                    label={item.label}
                    text={item.text}/>)
                }
            </Row>
        </Container>
    );
};

export default SecondarySection;