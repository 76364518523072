import moment from "moment";

export const getProgramFinalPrice = program => {
    if(!program) return 0;

    return !program.saleStartAt
        ? program.regularPrice
        : moment().isBetween(
            moment(program.saleStartAt).startOf('day'),moment(program.saleExpireAt).endOf('day'), "days", "[]"
        ) ? (program.salePrice || 0) : program.regularPrice;
};

export const userCommentNameFormat = (user, altField = 'storefrontName') => {
    if(!user) return;
    if(user.firstName || user.lastName) {
        return `${user.firstName || "" } ${ user?.lastName.toString().toUpperCase()[0] || "" }.`.trim()
    }
    return user[altField];
};

export const userName = (user, altField = 'storefrontName') => {
    if(!user) return;
    if(user.firstName || user.lastName) {
        return `${user.firstName || "" } ${ user?.lastName || "" }`.trim()
    }
    return user[altField];
};