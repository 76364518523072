import React from 'react';
import {Container, Row} from "reactstrap";
import Colxx from "../../Colxx";
import GlideOnlyArrowComponent from "../../carousel/GlideOnlyArrowComponent";
import Link from "next/link";
import TrainerCard from "./TrainerCard";
import {GLIDE_SETTINGS} from "../../../const/glide";
import styles from "../WorkoutPrograms/workoutPrograms.module.scss";

const Trainers = ({trainers}) => {
    return (
        <section className="products-block">
            <Container>
                <div className="featured-text">
                    Featured Trainers
                </div>
                <Row className="m-t">
                    {trainers && !!trainers.length && <Colxx>
                        <GlideOnlyArrowComponent settings={GLIDE_SETTINGS}>
                            {trainers.map(trainer => (
                                <Link
                                    href={'/landing/trainers/' + trainer.id}
                                    key={trainer.id}
                                    className={styles.link}
                                >
                                    <TrainerCard trainer={trainer}/>
                                </Link>
                            ))}
                        </GlideOnlyArrowComponent>
                    </Colxx>}
                </Row>
            </Container>
        </section>
    );
};

export default Trainers;