import React from 'react';
import Image from "next/legacy/image";
import Colxx from "../../Colxx";

const ItemSection = ({src, label, text}) => {
    return (
        <Colxx lg="4" className="d-flex flex-column align-items-center head-columns">
            <Image  src={src} alt="logo"/>
            <h2>{label}</h2>
            <p>{text}</p>
        </Colxx>
    );
};

export default ItemSection;