import React from 'react';
import Image from "next/legacy/image";
import {Container, Row} from "reactstrap";
import Colxx from "../../Colxx";

import commentAvatar from "../../../icons/landing/img-avatar-100.png";
import exclamationIcon from "../../../icons/landing/ic-exclamation.svg";

const Comment = ({img}) => {
    return (
        <>
            <div className="ml-5">
                <p>Duis vel feugiat lectus. Donec quis tortor vitae nibh pellentesque. vehicula. Sed in
                    mollis metus.</p>
                <div className="author">- Jessica Gibson</div>
            </div>
            <div className="avatar">
                <Image src={img} alt="avatar"/>
            </div>
        </>
    )
};

const Exclamation = () => {
    return (
        <>
            <div className="exclamation d-none d-lg-block">
                <Image src={exclamationIcon} alt="exclamation"/>
            </div>
            <h2 className="azure">
                These people love Trainest
                Lorem Ipsum dolor
            </h2>
        </>
    )
};

const Comments = () => {
    return (
        <section className="products-block comments-section">
            <Container>
                <Row className="info-section">
                    <Colxx lg="3">
                        <div className="d-block d-lg-none product-info m-b-md">
                            <Exclamation/>
                        </div>
                    </Colxx>
                    <Colxx lg="6" className="comment">
                        <Comment img={commentAvatar}/>
                    </Colxx>
                </Row>
                <Row className="info-section m-t-md">
                    <Colxx lg="6" className="product-info d-none d-lg-block">
                        <Exclamation/>
                    </Colxx>
                    <Colxx lg="6" className="comment">
                        <Comment img={commentAvatar}/>
                    </Colxx>
                </Row>
                <Row className="info-section m-t-md">
                    <Colxx lg="4"> </Colxx>
                    <Colxx lg="6" className="comment">
                        <Comment img={commentAvatar}/>
                    </Colxx>
                </Row>
            </Container>
        </section>
    );
};

export default Comments;