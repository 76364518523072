import Head from 'next/head'
import SearchSection from "../components/home/SearchSection";
import React from "react";
import SecondarySection from "../components/home/SecondarySection";
import WorkoutPrograms from "../components/home/WorkoutPrograms";
import {getCoachingPlans, getTrainers, getWorkoutList} from "../services/WorkoutProgramService";
import CoachingPlans from "../components/home/CoachingPlan";
import TrainersInfoSections from "../components/home/TrainersInfoSections";
import Trainers from "../components/home/Trainers";
import ConnectionsInfo from "../components/home/ConnectionsInfo";
import Mobille from "../components/home/Mobille";
import Comments from "../components/home/Comments";

export default function Home({workouts = [], coachingPlans = [], trainers = []}) {

  return (
    <>
      <Head>
        <title>Trainest</title>
        <meta name="description" content="Trainest start page next app" />
      </Head>

      <main>
          <SearchSection/>
          <SecondarySection/>
          <WorkoutPrograms workouts={workouts}/>
          <CoachingPlans coachingPlans={coachingPlans}/>
          <TrainersInfoSections/>
          <Trainers trainers={trainers}/>
          <ConnectionsInfo/>
          <Mobille/>
          <Comments/>
      </main>
    </>
  )
}


export async function getStaticProps() {
    const [resWorkouts, resCoachingPlans, resTrainers] = await Promise.all([getWorkoutList({}), getCoachingPlans({}),  getTrainers({})]);
    const workouts = resWorkouts.data;
    const coachingPlans = resCoachingPlans.data;
    const trainers = resTrainers.data;
    return {
        props:{
            workouts,
            coachingPlans,
            trainers
        }
    }
}
