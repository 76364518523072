import React from 'react';
import styles from "../WorkoutPrograms/workoutPrograms.module.scss";
import Image from "next/legacy/image";
import {userName} from "../WorkoutPrograms/helpers";
import {Row} from "reactstrap";
import Colxx from "../../Colxx";
import consultGreen from '../../../icons/landing/icons/ic-consultation-green.svg'
import consultBlue from '../../../icons/landing/icons/ic-consultation-blue.svg'
import Rating from "../../Rating";
import CoachingPlanLevel from "./CoachingPlanLevel";
import Price from "../../Price/Price";
import blur from "../../../public/883.jpg";
import base from "../../../icons/landing/baseProgram.jpg"
import {blurBase64} from "../../../const/icons"
import nutritionBlue from "../../../icons/landing/icons/ic-nutrition-blue.svg";

const CoachingPlanCard = ({product}) => {
    const price = product.regularPrice;

    return (
        <article className={styles.card + " " + styles.l__product__card}>
            <Image
                src={product.picture.resizedLink || product.picture.link || base}
                alt={product.title}
                loading={"eager"}
                blurDataURL={blurBase64}
                placeholder={"blur"}
                height={476}
                width={476}
                className={styles.img__card}
            />

            <div className={styles.content}>
                <h3 className={styles.title}>{product.title}</h3>
                <p className={styles.author + " text-truncate text-gray"}>
                    {`By ${userName(product.author)}`}
                </p>
                <Row className={styles.badges}>
                    {product?.hasFreeAssessmentConsultations && (
                        <Colxx xxs={6}>
                            <div className={styles.badge}>
                                <Image
                                    loading={"eager"}
                                    src={consultBlue}
                                    alt={'calls'}
                                    width={18}
                                    height={18}
                                />
                                <span className="ml-2 text-gray">Meet</span>
                            </div>
                        </Colxx>
                    )}
                    {product.hasFreeConsultationsForPlan && (
                        <Colxx xxs={6}>
                            <div className={styles.badge}>
                                <Image
                                    loading={"eager"}
                                    src={consultGreen}
                                    alt={"free-call"}
                                    width={18}
                                    height={18}
                                />
                                <span className="ml-2 text-gray">
                                    {`${product.numberOfFreeConsultationsPerMonth}/mo.`}
                                </span>
                            </div>
                        </Colxx>
                    )}
                </Row>
                <Rating rate={product.rating}/>
                <div className={styles.level + " d-flex align-items-center justify-content-between"}>
                  <div>
                    <CoachingPlanLevel levelId={product.level}/>
                  </div>
                  <div className="plan-level-checkbox" hidden={!product.customNutritionPlan}>
                    <span className="img">
                      <Image
                        src={nutritionBlue}
                        loading={"eager"}
                        width={16}
                        height={16}
                        alt=""
                      />
                    </span>
                    <span className='ml-2'>{"Nutrition"}</span>
                  </div>
                </div>
                <Price
                    price={price}
                    oldPrice={product.regularPrice !== price && product.regularPrice}
                    isProgram={false}
                />
            </div>
        </article>
    );
};

export default CoachingPlanCard;