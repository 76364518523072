import React from "react";
import Image from "next/legacy/image";
import starActive from '../icons/landing/icons/ic_ratings_active.svg'
import starInactive from '../icons/landing/icons/ic_ratings_inactive.svg'
import {DOT} from "../const/message";
import {formatRate} from "../helpers/formatRate";

export const Star = ({isActive, width , height}) => {
    if(isActive)
        return <div className="star">
            <Image
                loading={"eager"}
                alt={"★"}
                src={starActive}
                width={width}
                height={height}
            />
    </div>;
    return <div className="star">
        <Image
            loading={"eager"}
            alt={"☆"}
            src={starInactive}
            width={width}
            height={height}
        />
    </div>
};

const HalfStar = ({width, height}) => {
    return(
        <span className="half__conteiner">
            <Image
                loading={"eager"}
                alt={"★"}
                src={starInactive}
                width={width}
                height={height}
                className="half__star__inactive"
            />
            <Image
                loading={"eager"}
                alt={"☆"}
                src={starActive}
                width={width}
                height={height}
                className="half__star__active"
            />
        </span>
    )
}

const STAR_LENGTH = 5;
const MIN_VALUE_HALFSTAR = 0.28;
const MAX_VALUE_HALFSTAR = 0.8;

function choiceStar(rate, width, height) {
    const isActiveStar = rate >= 1 || rate % 1 >= MAX_VALUE_HALFSTAR;
    const isHalfStar = rate < 1 && rate % 1 > MIN_VALUE_HALFSTAR && rate % 1 < MAX_VALUE_HALFSTAR;

    if(isActiveStar )return <Star isActive={true} height={height} width={width}/>;
    if(isHalfStar) return <HalfStar height={height} width={width}/>;
    return <Star isActive={false} height={height} width={width}/>;
}

function aggregate(rate, width, height){
    const rating = [];
    for(let i = 0; i < STAR_LENGTH; i++){
        rating.push(choiceStar(rate, width, height));
        rate--;
    }
    return rating;
}

const Rating = ({rate, className = "align-items-center", rateSpan = DOT, width = 13, height = 14}) => {
    return(
    <div className={"rating " + className}>
        <div className="stars__container">{aggregate(rate, width, height)}</div>
        <span className="ml-1 text-gray">{formatRate(rateSpan, rate)}</span>
    </div>
    )
};

export default Rating;