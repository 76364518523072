import React, {useState} from 'react';
import {Button, Container} from "reactstrap";
import Image from "next/legacy/image";
import {useRouter} from "next/router";
import headerLogo from "../../../icons/landing/home-header-logo.png";
import styles from './search.module.scss'
import {COACHING} from "../../../const/services/API";

const SearchSection = () => {

    const [query, setQuery] = useState('');
    const router = useRouter();

    const handleSearch = () => {
        router.push("/landing" + COACHING + '?query=' + query)
    };

    return (
        <section className="head">
            <Container>
                <section className="search-section">
                    <section className="head-info">
                        <h1>
                            The largest verified online personal training marketplace
                        </h1>
                        <p className={styles.paragraph}>
                            Trainest is a platform that connects clients and certified fitness trainers worldwide
                        </p>
                        <div className={styles.search__input__group}>
                            <input
                                placeholder="Search for coaching plans"
                                value={query}
                                onChange={e => setQuery(e.target.value)}
                            />
                            <Button onClick={handleSearch}>Search</Button>
                        </div>
                    </section>
                    <div className="head-logo">
                        <Image
                            src={headerLogo}
                            alt={'work'}
                            priority
                            placeholder={"blur"}
                        />
                    </div>
                </section>
            </Container>
        </section>
    );
};

export default SearchSection;