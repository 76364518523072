import React from 'react';
import Rating from "../../Rating";
import {userName} from "../WorkoutPrograms/helpers";
import Image from "next/legacy/image";
import styles from './trainers.module.scss'
import avatar from '../../../icons/landing/trainers/img-program-thumbnail.jpg'
import {blurBase64} from "../../../const/icons";

const CardText = ({count, text}) => {
    return (
        <div className={styles.text}>
            <span>{count}</span>
            <span className="text-gray ml-1"> {text}</span>
        </div>
    )
};

const TrainerCard = ({trainer}) => {
    return (
        <div className={"card align-items-center " + styles.trainer__card}>
            <div className={styles.avatar__container}>
                <Image
                    src={trainer.avatar?.resizedLink || trainer.avatar?.link || avatar}
                    loading={"eager"}
                    alt={'avatar'}
                    width={210}
                    height={210}
                    className={styles.avatar}
                    blurDataURL={blurBase64}
                    placeholder={"empty"}
                />
            </div>
            <h2 className={styles.trainer__card__title}>{trainer.storefrontName || userName(trainer)}</h2>
            <div className={styles.trainer__name__card}>{userName(trainer)}</div>

            <Rating rate={trainer.totalRating} />

            <CardText text={"Workout Programs"} count={trainer.countWorkoutPrograms}/>
            <CardText text={"Coaching Plans"} count={trainer.countCoachingPlans}/>
        </div>
    );
};

export default TrainerCard;