import React from 'react';
import Image from "next/legacy/image";
import {Container, Row} from "reactstrap";
import Colxx from "../../Colxx";

import trainerLogo from '../../../icons/landing/img-trainer.png'
import trainerCert1 from '../../../icons/landing/img-cert-1.png'
import trainerCert2 from '../../../icons/landing/img-cert-2.png'
import trainerCert3 from '../../../icons/landing/img-cert-3.png'
import trainerCert4 from '../../../icons/landing/img-cert-4.png'

import styles from './trainers.module.scss'
import Link from "next/link";
import {DEFAULT_QUERY, TRAINERS} from "../../../const/links";

const certArray = [trainerCert1, trainerCert2, trainerCert3, trainerCert4];

const TrainersInfoSections = () => {
    return (
        <section className="products-block products-block-white trainer-section">
            <Container>
                <Row className="info-section">
                    <Colxx xxl="6" className="d-none d-xxl-block trainer">
                        <div className="img__trainer">
                            <Image
                                src={trainerLogo}
                                alt="logo"
                                layout={'fill'}
                                placeholder={"blur"}
                            />
                        </div>
                    </Colxx>
                    <Colxx xxl="6" className="product-info">
                        <h2 className="lime">
                            The best certified personal trainers
                        </h2>
                        <p>
                            Duis feugiat scelerisque ex sit amet eleifend. Ut mollis erat nec enim mattis, pulvinar
                            consectetur dolor molestie. Integer laoreet est eu ipsum mattis, sed aliquam diam
                            porttitor. Sed gravida eros id varius volutpat. Duis ultrices nec nisl eget malesuada.
                        </p>
                        <ul>
                            <li>Dolor vitae ullamcorper</li>
                            <li>Arcu odio, euismod fringilla viverra eu pharetra</li>
                            <li>Sed lacinia volutpat nibhconvallis tempus</li>
                        </ul>
                        <div className="certificates">
                            {certArray.map(
                                (cert, index) => <span className="certificate">
                                    <Image
                                        key={index}
                                        src={cert}
                                        alt={"certtificate"}
                                    />
                                </span>
                                )}
                        </div>
                        <div className={styles.btn}>
                            <Link href={{pathname: TRAINERS, query: DEFAULT_QUERY}}>

                                <span>{"Browse Trainers"}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 48 48">
                                    <path fill="#fff"
                                          fillRule="evenodd"
                                          d="M33.305 46.122C44.67 41.34 50.561 28.724 46.933 16.94 43.305 5.156 31.338-1.961 19.253.477 7.165 2.917-1.106 14.117.12 26.386 1.345 38.655 11.668 47.999 23.997 48c3.197.004 6.363-.634 9.308-1.878zM32.53 3.888C42.942 8.273 48.338 19.836 45.01 30.633c-3.328 10.798-14.296 17.318-25.37 15.08C8.563 43.475.986 33.209 2.111 21.966S12.698 2.162 23.997 2.164c2.93-.005 5.833.581 8.532 1.723zm-8.581 29.808l8.75-8.488c.403-.39.403-1.024 0-1.415l-8.75-8.488c-.259-.26-.642-.364-1.003-.272-.36.091-.642.364-.737.714-.094.35.014.722.282.973l6.99 6.78H17.03c-.57 0-1.031.447-1.031 1 0 .552.462 1 1.031 1H29.48l-6.99 6.78c-.268.251-.376.623-.282.973.095.35.376.623.737.714.36.092.744-.012 1.003-.272z"
                                    />
                                </svg>

                            </Link>
                        </div>
                    </Colxx>
                </Row>
            </Container>
        </section>
    );
};

export default TrainersInfoSections;