import {NUMBER, SLASH} from "../const/message";

const DEFAULT_RATING = 0;
export const getRating = (rating) =>
    !rating ? DEFAULT_RATING : rating.toFixed(1);

export const formatRate = (format, rate) => {
    if(format === SLASH){
        return `${rate}/5`
    }
    if(format === NUMBER){
        return rate
    }
    if(format === null)
        return
    return getRating(rate);
};