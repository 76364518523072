import React from 'react';
import {Container, Row} from "reactstrap";
import Colxx from "../../Colxx";
import Image from "next/legacy/image";

import dailyConnectionIcon from "../../../icons/landing/icons/ic-daily.png";
import calendarConnectionIcon from "../../../icons/landing/icons/ic-calendar.png";
import planConnectionIcon from "../../../icons/landing/icons/ic-plan.png";
import progressConnectionIcon from "../../../icons/landing/icons/ic-progress.png";

const ConnectionsInfo = () => {
    return (
        <section className="products-block products-block-white">
            <Container>
                <Row className="info-section">
                    <Colxx lg="5" className="product-info">
                        <h2 className="azure">
                            Connecting trainers and trainees
                        </h2>
                        <p>
                            Duis vel feugiat lectus. Donec quis tortor vitae nibh pellentesque
                            vehicula. Sed in mollis metus.
                        </p>
                    </Colxx>
                    <Colxx lg="6" className="offset-lg-1">
                        <Row>
                            <Colxx md="6" className="connections">
                                <span className="img__connections">
                                    <Image src={planConnectionIcon} alt="logo" placeholder={"blur"}/>
                                </span>
                                <h2>Workout Programs</h2>
                                <p>Duis ut ex sed quam mattis congue. sed tortor rhoncus, vulpu tate mi nec.</p>
                            </Colxx>
                            <Colxx md="6" className="connections">
                                <span className="img__connections">
                                    <Image src={calendarConnectionIcon} alt="logo" placeholder={"blur"}/>
                                </span>
                                <h2>Coaching Plans</h2>
                                <p>Duis ut ex sed quam mattis congue. sed tortor rhoncus, vulpu tate mi nec.</p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx md="6" className="connections">
                                <span className="img__connections">
                                    <Image src={dailyConnectionIcon} alt="logo" placeholder={"blur"}/>
                                </span>
                                <h2>Daily Nutrition Tracking</h2>
                                <p>Duis ut ex sed quam mattis congue. sed tortor rhoncus, vulpu tate mi nec.</p>
                            </Colxx>
                            <Colxx md="6" className="connections">
                                <span className="img__connections">
                                    <Image src={progressConnectionIcon} alt="logo" placeholder={"blur"}/>
                                </span>
                                <h2>Progress Tracking</h2>
                                <p>Duis ut ex sed quam mattis congue. sed tortor rhoncus, vulpu tate mi nec.</p>
                            </Colxx>
                        </Row>
                    </Colxx>
                </Row>
            </Container>
        </section>
    );
};

export default ConnectionsInfo;