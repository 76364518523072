import React from 'react';
import {Container, Row} from "reactstrap";
import styles from './coachind.module.scss'
import InfoSection from "../InfoSection";

import coachLogo from '../../../icons/landing/illustration-goals.png'
import GlideOnlyArrowComponent from "../../carousel/GlideOnlyArrowComponent";
import {GLIDE_SETTINGS} from "../../../const/glide";
import Colxx from "../../Colxx";

import CoachingPlanCard from "./CoachingPlanCard";
import Link from "next/link";
import {COACHING_PLANS} from "../../../const/links";

const CoachingPlans = ({coachingPlans}) => {
    return (
        <section className="products-block products-block-dark">
            <Container>
                <InfoSection
                    label={'Maximise your gains with personal coaching'}
                    text={`Duis feugiat scelerisque ex sit amet eleifend. Ut mollis erat nec enim mattis, pulvinar
                           consectetur dolor molestie. Integer laoreet est eu ipsum mattis, sed aliquam diam
                           porttitor. Sed gravida eros id varius volutpat. Duis ultrices nec nisl eget malesuada.`
                    }
                    linkText={'Browse Plans'}
                    img={coachLogo}
                    classNameSection={styles.product__info}
                    classNameBtn={styles.btn}
                    link={{pathname: COACHING_PLANS}}
                />
                <div className="featured-text">
                    Featured Coaching Plans
                </div>
                <Row className="m-t">
                    {coachingPlans && !!coachingPlans.length && <Colxx>
                        <GlideOnlyArrowComponent settings={GLIDE_SETTINGS}>
                            {coachingPlans.map(plan => (
                                <Link
                                    href={COACHING_PLANS + '/' + plan.id}
                                    key={plan.id}
                                    className={styles.link}
                                >
                                    <CoachingPlanCard key={plan.id} product={plan}/>
                                </Link>
                            ))}
                        </GlideOnlyArrowComponent>
                    </Colxx>}
                </Row>
            </Container>
        </section>
    );
};

export default CoachingPlans;